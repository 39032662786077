.button {
	font-family: "RobotoCondensed" !important;
	font-size: 1em !important;
	font-weight: 300 !important;
	width: 100% !important;
	justify-content: center !important;
	border-radius: 8px !important;
	margin: 0.5em 0 !important;
	padding: 0.5em 0 !important;
}

.text {
	font-family: "RobotoCondensed";
	font-size: 1em;
}

.titleText {
	font-family: "RobotoCondensed";
	font-weight: bold;
	font-size: 1.2em;
}

.checkoutContainer {
	width: 100%;
}

.checkoutInfoContainer {
	display: flex;
	justify-content: space-between;
}

.cartInfo {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0.5em;
	justify-content: center;
	align-items: flex-start;
}

.checkoutInfo {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0.5em;
	justify-content: center;
	align-items: flex-end;
}

.cartItems {
	width: 100%;
}

.modalActions {
	display: flex;
}

.actionButton {
	font-family: "RobotoCondensed" !important;
	font-size: 1em !important;
	font-weight: 300 !important;
	width: 100% !important;
	justify-content: center !important;
	border-radius: 8px !important;
	margin: 0.5em !important;
	padding: 0.5em 0 !important;
}
