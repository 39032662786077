.container {
	display: flex;
	margin: 0.5em 0;
	padding: 0.5em;
	border: 1px solid;
	border-radius: 8px;
	justify-content: center;
	align-items: center;
}

.titleText {
	font-family: "RobotoCondensed";
	font-weight: bold;
	font-size: 1em;
	text-align: center;
	display: flex;
	flex: 1;
}

.text {
	font-family: "RobotoCondensed";
	font-weight: bold;
	font-size: 1em;
}

.boulpikContainer {
	display: flex;
	flex: 4;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
}

.boulpiks {
	background-color: #eaeaea;
	height: 2.5em;
	width: 2.5em;
	border-radius: 20px;
	border: 1px solid;
	display: flex;
	justify-content: center;
	align-items: center;
}
.containerComponent {
	border: 1px solid !important;
	border-radius: 8px !important;
	width: 100% !important;
	margin: 0.5em 0 !important;
}