.contdownContainer {
	display: flex;
	flex-direction: row;
	margin-bottom: 1.5em;
}

.timerContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	margin: 0px 5px;
}

.timerBox {
	padding: 10px;
	margin-left: 4px;
	margin-right: 4px;
	border: 1px solid;
	border-radius: 4px;
	font-family: "RobotoCondensed";
	font-size: 18px;
}

.timerLabel {
	font-family: "RobotoCondensed";
	font-size: 14px;
	margin-top: 4px;
}
