.container {
	display: flex;
	flex-direction: column;
	padding: 0.5em;
	margin: 0.5em 0;
	width: 100%;
	border: 1px solid;
	border-radius: 8px;
}

.topSide {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.bottomSide {
	display: flex;
	justify-content: center;
	align-items: center;
}

.text {
	font-family: "RobotoCondensed";
	font-size: 1em;
}

.textPrice {
	font-family: "RobotoCondensed";
	font-size: 1em;
	color: rgb(5, 193, 61);
}

.textTotal {
	font-family: "RobotoCondensed";
	font-size: 1em;
	padding-top: 0.4em;
	color: rgb(5, 193, 61);
}

.titleText {
	font-family: "RobotoCondensed";
	font-weight: bold;
	font-size: 1em;
}

.infoContainer {
	display: flex;
	flex-direction: column;
	flex: 3;
	justify-content: space-between;
	align-items: center;
	height: 4.8em;
}

.priceContainer {
	display: flex;
	flex-direction: column;
	flex: 2;
	justify-content: space-between;
	align-items: center;
	height: 3.5em;
	padding-bottom: 0.75em;
}

.boulpikContainer {
	display: flex;
	flex: 4;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
}

.boulpiks {
	background-color: #eaeaea;
	height: 2em;
	width: 2em;
	border-radius: 16px;
	border: 1px solid;
	display: flex;
	justify-content: center;
	align-items: center;
}

.boulpikText {
	font-family: "RobotoCondensed";
	font-weight: bold;
	font-size: 1em;
}

.button {
	font-family: "RobotoCondensed" !important;
	font-size: 1em !important;
	font-weight: 300 !important;
	width: 100% !important;
	justify-content: center !important;
	border-radius: 8px !important;
	margin: 0.5em !important;
	padding: 0.5em 0 !important;
}
