.container {
  display: flex;
  flex-direction: row;
  padding: 0.5em 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 6em;
}

.rankContainer {
  display: flex;
  flex-direction: column;
  flex: 2;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}

.arrowContainer {
  display: flex;
  flex: 0.5;
  align-items: center;
  justify-content: center;
}

.prizeContainer {
  display: flex;
  flex: 1.5;
  align-items: center;
  justify-content: center;
}

.image {
  display: flex !important;
  width: 3em;
  object-fit: contain;
}

.text {
  font-family: "RobotoCondensed";
  font-size: 1.1em;
}

.boulpikContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.boulpiks {
  background-color: #eaeaea;
  height: 1.2em;
  width: 1.2em;
  border-radius: 12px;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boulpikText {
  font-family: "RobotoCondensed";
  font-weight: bold;
  font-size: 0.7em;
}
