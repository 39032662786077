.text {
	font-family: "RobotoCondensed";
	font-size: 1.1em;
	font-weight: bold;
}

.spacer {
	margin-bottom: 1.5em !important;
}

.button {
	display: flex !important;
	flex-direction: row !important;
	width: 90% !important;
	background-color: #ce1d1a !important;
	color: #ffffff !important;
	justify-content: center !important;
	align-items: center !important;
	border-radius: 1.5em !important;
	line-height: 0.9 !important;
}
.buttonsContainer {
	display: flex !important;
	flex-direction: column !important;
	flex: 1 !important;
	align-items: center !important;
	justify-content: space-around !important;
	margin: 0.2em !important;
}
.inputsContainer {
	display: flex !important;
	flex-direction: column !important;
	flex: 3 !important;
	justify-content: center !important;
	margin: 0.5em !important;
}
