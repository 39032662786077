.notificationContainer {
	cursor: pointer;
	margin-bottom: 0.5em;
	margin-right: 0.5em;
	padding: 0.5em;
	border-radius: 0.5em;
	border: 1px solid;
}

.notificationContainer:hover {
	filter: brightness(85%);
}

.notificationTextColor {
	color: teal !important;
}

.content .extra {
	margin: 0px !important;
	padding-left: 5px !important;
}

.notificationTimeStyle {
	display: flex;
	justify-content: flex-end;
	margin-top: 5px !important;
	color: rgba(0, 0, 0, 0.6) !important;
}

.notificationHeader {
	font-family: "RobotoCondensed" !important;
	font-weight: bold !important;
	font-size: 1.2em !important;
	margin: 0 !important;
	display: flex !important;
	align-items: center !important;
}

.feed {
	display: flex;
	justify-content: space-between;
}

.feedIcon {
	margin-right: 0.5em !important;
	font-size: 1.2em !important;
}

.feedText {
	margin: 0 !important;
	flex: 9 !important;
}

.deleteIcon {
	margin: 0 !important;
	padding: 0.25em !important;
	font-size: 1.3em !important;
	flex: 1 !important;
	display: flex !important;
	align-items: center !important;
	justify-content: flex-end !important;
}
