.button {
  font-family: "RobotoCondensed" !important;
  font-size: 1.1em !important;
  font-weight: 300 !important;
  width: 100% !important;
  justify-content: center !important;
  border-radius: 8px !important;
  margin: 0.5em 0 !important;
}

.text {
  font-family: "RobotoCondensed";
  font-size: 1.2em;
  font-weight: bold;
}

.answerText {
  font-family: "RobotoCondensed";
  font-size: 1em;
  font-weight: 300;
  white-space: pre-line;
}

.container {
  width: 100% !important;
  margin: 0.5em 0 !important;
}

.title {
  flex: 9;
}

.titleIcon {
  flex: 1;
}

.titleText {
  font-family: "RobotoCondensed" !important;
  font-weight: bold !important;
  font-size: 1.1em !important;
  display: flex !important;
  justify-content: center !important;
  padding: 0.8em 0 !important;
  border-radius: 0.5em !important;
}

.contentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0.5em;
}
