.text {
	font-family: "RobotoCondensed";
	font-size: 1.1em;
	font-weight: bold;
}

.spacer {
	margin-bottom: 1.5em !important;
}

.button {
	font-family: "RobotoCondensed" !important;
	font-size: 1.1em !important;
	font-weight: 300 !important;
	width: 100% !important;
	justify-content: center !important;
	border-radius: 8px !important;
	margin-bottom: 1.5em !important;
}
