.row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.text {
	font-family: "RobotoCondensed";
	font-size: 1.2em;
}

.textCompteOff {
	font-family: "RobotoCondensed";
	font-size: 1.2em;
	margin-left: 0.95em;
}

.textImprevu {
	font-family: "RobotoCondensed";
	font-size: 1.2em;
	margin-left: 2em;
}

.select {
	min-width: 0 !important;
	min-height: 0 !important;
	background: none !important;
	border: 0 !important;
	text-align: right !important;
	font-family: "RobotoCondensed";
	font-size: 1.2em;
	display: flex !important;
	flex-direction: row !important;
	justify-content: flex-end !important;
	align-items: center !important;
}

.percent {
	display: flex;
	align-items: center;
}
