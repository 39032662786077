.balanceContainer {
	display: flex !important;
	flex: 1 !important;
	align-items: flex-start !important;
	justify-content: center !important;
	margin: 0.5em 0 !important;
	width: 100% !important;
}

.inputsContainer {
	display: flex !important;
	flex-direction: column !important;
	margin: 0.5em 0 !important;
	width: 100% !important;
}

.divider {
	width: 100% !important;
	margin: 1.5rem 0 !important;
}
