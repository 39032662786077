.redCircle {
	height: 1em;
	width: 1em;
	border-radius: 0.5em;
	background-color: red;
	margin-right: 0.5em;
}

.legend {
	font-family: "RobotoCondensed";
	font-weight: bold;
	font-size: 1.2em;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid;
	border-radius: 0.25em;
	margin-bottom: 0.5em;
	padding: 0.5em 0;
}
