.row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 70%;
	margin: 0.5%;

	/* padding-right: 30px; */

	padding-left: 30%;
}

.qrcode {
	justify-content: center;
	align-items: center;
	display: flex;
	width: 100%;
}

.text {
	font-family: "RobotoCondensed";
	font-size: 1.2em;
}

.spacer {
	margin-bottom: 1.5em !important;
}

.select {
	min-width: 0 !important;
	min-height: 0 !important;
	background: none !important;
	border: 0 !important;
	text-align: right !important;
	font-family: "RobotoCondensed";
	font-size: 1.2em;
	display: flex !important;
	flex-direction: row !important;
	justify-content: flex-end !important;
	align-items: center !important;
}

.percent {
	display: flex;
	align-items: center;
}

.actionButton {
	font-family: "RobotoCondensed" !important;
	font-size: 1em !important;
	font-weight: 300 !important;
	width: 20% !important;
	justify-content: center !important;
	border-radius: 8px !important;
	margin: 0.5em !important;
	padding: 0.5em 0 !important;
}
