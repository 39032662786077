.container {
	background-image: url("/background.png") !important;
	background-size: cover !important;
	width: 100% !important;
	min-height: 100vh !important;
	display: flex !important;
	flex-direction: column !important;
}

.logoContainer {
	display: flex !important;
	flex: 1 !important;
	align-items: center !important;
	justify-content: center !important;
	margin: 3em 0.5em 0.5em 0.5em !important;
}

.inputsContainer {
	display: flex !important;
	flex-direction: column !important;
	flex: 3 !important;
	justify-content: center !important;
	margin: 0.5em !important;
	padding: 0.15em !important;
}

.buttonsContainer {
	display: flex !important;
	flex-direction: column !important;
	flex: 2 !important;
	align-items: center !important;
	justify-content: space-around !important;
	margin: 0.5em !important;
}

.actionsContainer {
	display: flex !important;
	align-items: center !important;
	justify-content: space-between !important;
	margin: 0.5em !important;
}

.logo {
	width: 50% !important;
	object-fit: scale-down !important;
}

.button {
	display: flex !important;
	flex-direction: row !important;
	width: 80% !important;
	background-color: #ce1d1a !important;
	color: #ffffff !important;
	justify-content: center !important;
	align-items: center !important;
	border-radius: 1.5em !important;
	line-height: 0.9 !important;
}

.text {
	font-family: "RobotoCondensed" !important;
	font-size: 1em !important;
}

.hyperlink {
	font-family: "RobotoCondensed" !important;
	font-size: 1em !important;
	color: #ce1d1a !important;
	margin-left: 0.4em !important;
}

.signUpText {
	display: flex !important;
	flex-direction: row !important;
	justify-content: center;
}

.textCenter {
	display: flex !important;
	justify-content: center;
	padding-top: 1.2em;
	font-size: 1.1em !important;
}


