.balanceContainer {
  display: flex !important;
  flex: 1 !important;
  align-items: flex-start !important;
  justify-content: center !important;
  margin: 0.5em 0 !important;
  width: 100% !important;
}

.inputsContainer {
  display: flex !important;
  flex-direction: column !important;
  flex: 2 !important;
  margin: 0.5em 0 !important;
  width: 100% !important;
}

.buttonsContainer {
  display: flex !important;
  flex-direction: column !important;
  flex: 1 !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0.5em 0 !important;
  width: 100% !important;
}

.button {
  display: flex !important;
  flex-direction: row !important;
  width: 80% !important;
  background-color: #ce1d1a !important;
  color: #ffffff !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 1.5em !important;
  line-height: 0.9 !important;
}

.text {
  font-family: "RobotoCondensed" !important;
  font-size: 1em !important;
}

.titleText {
  font-family: "RobotoCondensed" !important;
  font-weight: bold;
  font-size: 1.2em !important;
}

.content {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}
