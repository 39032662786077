.menuContainer {
	width: 100% !important;
	min-height: 100vh !important;
}

.profileContainer {
	display: flex !important;
	flex-direction: column !important;
	height: 300px !important;
	padding: 0.9em 1.1em 0 1.1em !important;
}

.profileBalanceContainer {
	display: flex;
	padding: 5px;
	border-radius: 25px;
	align-items: center;
	justify-content: center;
	background-color: #b30000;
}

.profileBalanceText {
	font-family: "RobotoCondensed";
	font-size: 18px;
	color: #ffffff;
}

.profileText {
	font-family: "RobotoCondensed";
	font-size: 20px;
	font-weight: bold;
}

.profileSubText {
	font-family: "RobotoCondensed";
	font-size: 16px;
}

.profileTextContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.profile {
	flex: 1;
	justify-content: space-around;
	display: flex;
	flex-direction: column;
	padding: 0.5em 0;
}

.logo {
	flex: 1;
}

.backIcon {
	position: absolute;
	top: 20px;
	z-index: 2;
}

.editIcon {
	position: absolute;
	top: 20px;
	z-index: 2;
}

.menuIcon {
	margin: 0 0.8em 0 0 !important;
	float: left !important;
}

.menuItem {
	display: flex !important;
	padding: 0.9em 1.1em !important;
}

.activeMenuItem {
	display: flex !important;
	background-color: #dadada !important;
	color: #ce1d1a !important;
	padding: 0.7em 1em !important;
}

.menuButtonContainer {
	display: flex !important;
	flex-direction: column !important;
	justify-content: center !important;
	height: 60px !important;
}

.menuButton {
	display: flex !important;
	background-color: #ce1d1a !important;
	border-radius: 10px !important;
	align-items: center !important;
	justify-content: center !important;
	color: #ffffff !important;
	width: 100% !important;
	padding: 0.5em 0 !important;
}
