.container {
	border: 1px solid !important;
	border-radius: 8px !important;
	width: 100% !important;
	margin: 0.5em 0 !important;
}

.title {
	flex: 9;
}

.titleIcon {
	flex: 1;
}

.titleText {
	font-family: "RobotoCondensed" !important;
	font-weight: bold !important;
	font-size: 1em !important;
	text-align: center !important;
	display: flex !important;
	justify-content: center !important;
	padding: 0.8em 0 !important;
	border-radius: 0.5em !important;
}

.expandedTitleText {
	font-family: "RobotoCondensed" !important;
	font-weight: bold !important;
	font-size: 1em !important;
	text-align: center !important;
	color: #ffffff !important;
	display: flex !important;
	justify-content: center !important;
	padding: 0.8em 0 !important;
	border-radius: 0.5em 0.5em 0 0 !important;
	background-color: #ce1d1a !important;
}

.contentContainer {
	display: flex;
	flex: 1;
	flex-direction: column;
	margin: 0 0.5em;
}

.noListContainer {
	border: 1px solid !important;
	border-radius: 8px !important;
	width: 100% !important;
	margin: 0.5em 0 !important;
}

.redNoListContainer {
	border: 1px solid !important;
	border-radius: 8px !important;
	width: 100% !important;
	margin: 0.5em 0 !important;
	background-color: #ce1d1a;
}
