.text {
	font-family: "RobotoCondensed";
	font-size: 1em;
}

.titleText {
	font-family: "RobotoCondensed";
	font-weight: bold;
	font-size: 1.2em;
	text-align: center;
	margin: 0.5em 0 !important;
}

.content {
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
}
