.container {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
}

.inputsContainer {
	display: flex !important;
	flex-direction: column !important;
	flex: 9 !important;
	margin: 0.5em 0 !important;
}

.buttonsContainer {
	display: flex !important;
	flex-direction: column !important;
	flex: 1 !important;
	align-items: center !important;
	justify-content: center !important;
	margin: 0.5em 0 !important;
}

.button {
	display: flex !important;
	flex-direction: row !important;
	width: 80% !important;
	background-color: #ce1d1a !important;
	color: #ffffff !important;
	justify-content: center !important;
	align-items: center !important;
	border-radius: 1.5em !important;
	line-height: 0.9 !important;
}

.button2 {
	display: flex !important;
	flex-direction: row !important;
	width: 80% !important;
	background-color: #56423e !important;
	color: #ffffff !important;
	justify-content: center !important;
	align-items: center !important;
	border-radius: 1.5em !important;
	line-height: 0.9 !important;
}

.divider {
	width: 80% !important;
}

.text {
	font-family: "RobotoCondensed" !important;
	font-size: 1em !important;
}

.titleText {
	font-family: "RobotoCondensed" !important;
	font-weight: bold;
	font-size: 1.2em !important;
}

.boulpikInfoContainer {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5em;
}

.autoBoulpik {
	display: flex;
	justify-content: center;
	width: 100%;
	margin: 0.5em 0 0 0;
}
