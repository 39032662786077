.container {
	display: flex;
	flex-direction: column;
	padding: 0.5em;
	margin: 0.5em 0;
	width: 100%;
	border: 1px solid;
	border-radius: 8px;
}

.topContainer {
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: space-evenly;
	width: 100%;
	padding: 0.5em;
}

.insideContainer {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-evenly;
	width: 100%;
	padding: 0.5em;
}

.centerContainer {
	display: flex;
	flex: 1;
	justify-content:center;
	align-items: center;
	width: 100%;
	padding: 0.5em;
}

.topSide {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.bottomSide {
	display: flex;
	justify-content: center;
	align-items: center;
}

.text {
	font-family: "RobotoCondensed";
	font-size: 1em;
}

.textPrice {
	font-family: "RobotoCondensed";
	font-size: 1em;
	color: rgb(5, 193, 61);
}

.textTotal {
	font-family: "RobotoCondensed";
	font-size: 1em;
	padding-top: 0.4em;
	color: rgb(5, 193, 61);
}

.titleText {
	font-family: "RobotoCondensed";
	font-weight: bold;
	font-size: 1em;
}
.leftPosition {
	display:inline-block ;
	width:150px ;
}
.rightPosition {
	display:inline-block ;
	width:150px ;
}

.infoContainer {
	display: flex;
	flex-direction: column;
	flex: 3;
	justify-content: space-between;
	align-items: center;
	height: 7.8em;
}

.priceContainer {
	display: flex;
	flex-direction: column;
	flex: 2;
	justify-content: space-between;
	align-items: center;
	height: 5.5em;
	padding-bottom: 0.75em;
}

.boulpikContainer {
	display: flex;
	flex: 4;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
}

.boulpiks {
	background-color: #eaeaea;
	height: 2em;
	width: 2em;
	border-radius: 16px;
	border: 1px solid;
	display: flex;
	justify-content: center;
	align-items: center;
}

.boulpikText {
	font-family: "RobotoCondensed";
	font-weight: bold;
	font-size: 1em;
}

.button {
	font-family: "RobotoCondensed" !important;
	font-size: 1em !important;
	font-weight: 300 !important;
	width: 100% !important;
	justify-content: center !important;
	border-radius: 8px !important;
	margin: 0.5em !important;
	padding: 0.5em 0 !important;
}
.containerComponent {
	border: 1px solid !important;
	border-radius: 8px !important;
	width: 100% !important;
	margin: 0.5em 0 !important;
}
.titleIcon {
	flex: 1;
}
.title {
	flex: 9;
}
.contentContainer {
	display: flex;
	flex: 1;
	flex-direction: column;
	margin: 0 0.5em;
}
.titleTextAccordon {
	font-family: "RobotoCondensed" !important;
	font-weight: bold !important;
	font-size: 1em !important;
	text-align: center !important;
	display: flex !important;
	justify-content: center !important;
	padding: 0.8em 0 !important;
	border-radius: 0.5em !important;
}

.expandedTitleText {
	font-family: "RobotoCondensed" !important;
	font-weight: bold !important;
	font-size: 1em !important;
	text-align: center !important;
	color: #ffffff !important;
	display: flex !important;
	justify-content: center !important;
	padding: 0.8em 0 !important;
	border-radius: 0.5em 0.5em 0 0 !important;
	background-color: #ce1d1a !important;
}
.containerAccordon {
	border: 1px solid !important;
	border-radius: 8px !important;
	width: 100% !important;
	margin: 0.5em 0 !important;
}
