/*********************************************/
/*Reset.css*/

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/*************************************************/

body {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #ebebeb !important;
}

body::-webkit-scrollbar {
	width: 6px;
	background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: auto;
}

body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: #f5f5f5;
}

body ::-webkit-scrollbar {
	width: 6px;
	background-color: #f5f5f5;
}

body ::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: auto;
}

body ::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: #f5f5f5;
}

@media (min-width: 768.1px) {
	body {
		font-size: 20px;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
	font-family: "RobotoCondensed";
	src: local("RobotoCondensed"), url("./assets/fonts/RobotoCondensed-Regular.ttf") format("truetype");
}

.ui.checkbox label {
	padding-left: 22px !important;
}

.ui.button {
	margin: 0px;
}

.ui.disabled.input {
	opacity: 1 !important;
}

.ui.menu {
	margin: 0px !important;
	border: 0 !important;
}

.ui.secondary.pointing.menu {
	border-bottom: 0 !important;
}

.ui.selection.active.dropdown .menu {
	left: auto !important;
	min-width: 8em !important;
}

.ui.selection.dropdown {
	padding: 0px !important;
}

.ui.dropdown:not(.button) > .default.text {
	color: grey !important;
}

.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .search.icon {
	position: static !important;
	margin: 0 0 0 1em !important;
	padding: 0px !important;
}

.ui.modal .actions > .button {
	margin-left: 0 !important;
}

.ui.modal > .header {
	padding: 0.75rem 1rem !important;
}

.ui.pagination.menu .item {
	min-width: 2em !important;
	justify-content: center !important;
}

.ui.menu .item {
	padding: 0.5em;
}

.ui.pagination.menu .active.item {
	padding-top: 0.5em !important;
	background-color: rgba(0, 0, 0, 0.15) !important;
}

.ui.menu .item.disabled {
	background-color: #dadada !important;
	color: #ce1d1a !important;
}

.ui.menu .item.disabled,
.ui.menu .item.disabled:hover {
	background-color: #dadada !important;
	color: #ce1d1a !important;
}

/*
  Button
---------------------------------------------------------------------------------------------------------------------
*/
.btn {
	border: none;
	border-radius: 4px;
	text-transform: none;
	font-family: "RobotoCondensed";
	font-weight: 500;
	font-size: 21px;
	margin: 0px 0px;
}

/*
  End Button
---------------------------------------------------------------------------------------------------------------------
*/
/*
  Images
---------------------------------------------------------------------------------------------------------------------
*/
.img-responsive-by-width {
	max-width: 100%;
	height: auto;
}

.img-responsive-by-height {
	max-height: 100%;
	width: auto;
}
/*
  End Images
---------------------------------------------------------------------------------------------------------------------
*/
.bgContainer {
	height: 100vh;
	width: 100vw;
	z-index: -1;
	overflow: hidden;
}

input:focus {
	outline: 0;
}
