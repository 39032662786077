.pagination {
	display: flex !important;
	justify-content: space-between !important;
	align-items: center !important;
	width: 100% !important;
}

.smallPagination {
	height: 5em !important;
	display: flex !important;
	flex-direction: column;
	justify-content: space-between !important;
	align-items: flex-start !important;
	width: 100% !important;
}

.itemCount {
	font-family: "RobotoCondensed" !important;
	font-size: 1em !important;
	font-weight: bold !important;
	margin-left: 0.5em !important;
}

.text {
	font-family: "RobotoCondensed" !important;
	font-size: 1em !important;
}

.titleText {
	font-family: "RobotoCondensed" !important;
	font-size: 1em !important;
	font-weight: bold;
}
