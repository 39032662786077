.container {
  display: flex;
  margin: 0.5em 0;
  padding: 0.5em;
  border: 1px solid;
  border-radius: 0.5em;
  justify-content: space-around;
  align-items: center;
}

.input {
  font-family: "RobotoCondensed";
  font-size: 1px;
  color: white;
  background: transparent;
  padding: 0;
  border: 0;
  align-self: center;
}

.text {
  font-family: "RobotoCondensed";
  font-size: 1em;
}

.boulpik {
  background-color: #eaeaea;
  height: 2.5em;
  width: 2.5em;
  border-radius: 1.25em;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}
