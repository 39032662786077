.containerMonCash {
	display: flex;
	margin: 0.5em 0;
	padding: 0.5em;
	border: 1px solid;
	border-radius: 0.5em;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	width: 100%;
}

.containerLajanCash {
	display: flex;
	margin: 0.5em 0;
	padding: 0.5em;
	border: 1px solid;
	border-radius: 0.5em;
	justify-content: center;
	align-items: center;
	background-color: #facb01;
	width: 100%;
}

.containerCashMobile {
	display: flex;
	margin: 0.5em 0;
	padding: 0.5em;
	border: 1px solid;
	border-radius: 0.5em;
	justify-content: center;
	align-items: center;
	background-color: #0f3c50;
	width: 100%;
}

.image {
	height: 3em;
	width: 3em;
	border-radius: 0.5em;
	object-fit: contain;
}

.text {
	font-family: "RobotoCondensed";
	font-weight: bold;
	font-size: 1.5em;
	text-align: center;
	margin-left: 1em;
}

.cashMobileText {
	font-family: "RobotoCondensed";
	font-weight: bold;
	font-size: 1.5em;
	text-align: center;
	margin-left: 1em;
	color: #ffffff;
}
