.button {
	display: flex !important;
	flex-direction: row !important;
	width: 80% !important;
	background-color: #ce1d1a !important;
	color: #ffffff !important;
	justify-content: center !important;
	align-items: center !important;
	border-radius: 1.5em !important;
	line-height: 0.9 !important;
	margin-top: 1em !important;
}

.text {
	font-family: "RobotoCondensed" !important;
	font-size: 1em !important;
}

.inputsContainer {
	display: flex !important;
	flex-direction: column !important;
	margin: 0.5em !important;
	width: 100% !important;
}
