.input {
	font-family: "RobotoCondensed" !important;
	font-size: 1em !important;
	color: black !important;
	background: transparent !important;
	border: 0 !important;
	width: 100% !important;
}

.icon {
	margin-right: 1em !important;
}

.label {
	font-family: "RobotoCondensed" !important;
	font-size: 1em !important;
	font-weight: 200 !important;
	color: black !important;
}

.inputContainer {
	display: flex;
	align-items: center !important;
	border-bottom: 1px solid !important;
	border-radius: 0px !important;
	background: transparent !important;
	margin: 0.5em !important;
	padding: 0.5em !important;
}

.dropdown {
	font-family: "RobotoCondensed" !important;
	font-size: 1em !important;
	background: transparent !important;
	border: none !important;
	width: 100% !important;
	line-height: 1.15 !important;
	display: flex !important;
	justify-content: space-between !important;
	min-height: 20px !important;
}
