.headerContainer {
	height: 60px;
	padding: 0px 20px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.iconContainer {
	display: flex;
	flex: 1;
	font-size: 1em;
	justify-content: flex-start;
	align-items: center;
}

.endIconContainer {
	display: flex;
	flex: 1;
	font-size: 1em;
	justify-content: flex-end;
	align-items: center;
}

.titleContainer {
	display: flex;
	font-family: "RobotoCondensed";
	font-size: 1.5em;
	font-weight: bold;
	flex: 5;
	justify-content: flex-end;
	align-items: center;
}

.popup {
	padding: 0.5em !important;
}

.notification {
	overflow-y: scroll;
}

@media (min-width: 320px) {
	.notification {
		max-height: 400px;
		width: 275px;
	}
}

@media (min-width: 375px) {
	.notification {
		max-height: 400px;
		width: 310px;
	}
}

@media (min-width: 414px) {
	.notification {
		max-height: 400px;
		width: 345px;
	}
}
