.container {
	display: flex;
	flex-direction: column;
	padding: 0.5em;
	margin: 0.5em 0;
	width: 100%;
	border: 1px solid;
	border-radius: 8px;
}

.topSide {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.text {
	font-family: "RobotoCondensed";
	font-size: 1em;
}

.item {
	display: flex;
	align-items: center;
	padding: 0.5em;
}

.icon {
	color: black !important;
	font-size: 1em !important;
	padding-right: 0.5em !important;
}
