.header {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	font-family: "RobotoCondensed" !important;
}

.text {
	font-family: "RobotoCondensed" !important;
	text-align: justify !important;
	white-space: pre-line !important;
}
