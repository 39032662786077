.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.text {
  font-family: "RobotoCondensed";
  font-size: 1.2em;
}

.select {
  min-width: 0 !important;
  min-height: 0 !important;
  background: none !important;
  border: 0 !important;
  text-align: right !important;
  font-family: "RobotoCondensed";
  font-size: 1.2em;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.percent {
  display: flex;
  align-items: center;
}

.shadowStyle {
  box-shadow: 5px 10px;
  height: 20%;
  width: 25%;
  background-color: #f0f0f0;
  align-items: center;
}

.card {
  background-color: #c0c0c0;
  border-radius: 8px;
  padding: 2em 4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
